// Here you can add other styles
.MuiButton-root {
    text-transform: unset !important;
}

.bg-primary {
    background-color: #9e9e9e !important;
}

.cursor-pointer {
    &:hover {
        cursor: pointer;
    }
}

// Breadcrumb
.breadcrumb-div {
    background-color: white;
    color: rgba(62, 62, 62, 0.8);
    font-weight: 500;
    height: 56px;
}

.breadcrumb-div a {
    text-decoration: none;
    pointer-events: none;
    cursor: default;
    color: rgba(62, 62, 62, 0.8);
}

.breadcrumb-item {
    &.active {
        color: rgba(62, 62, 62, 0.8) !important;
    }
}

.text-error {
    color: rgb(255, 80, 80);
}

.cancel-search:hover {
    background-color: #dddddd;
}

// Mui
.MuiFilledInput-root {
    border-radius: 2px !important;

    &::before {
        border-bottom-style: none !important;
    }
}

.MuiFilledInput-root.Mui-error {
    box-shadow: rgb(255 80 80) 0 0 0 1px !important;
}

.MuiGrid-root.MuiGrid-item {
    padding-top: 14px !important;
}

.MuiInputBase-adornedEnd {
    &::after {
        border-bottom: none !important;
    }
}

.input-label {
    transform: translate(14px, 9px) scale(1) !important;

    &.Mui-focused {
        transform: translate(14px, -9px) scale(0.75) !important;
    }

    &.MuiFormLabel-filled {
        transform: translate(14px, -9px) scale(0.75) !important;
    }
}

.input-select {
    &.Mui-error {
        .MuiSelect-select {
            border-color: #d32f2f !important;
        }
    }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.w-10 {
    width: 10% !important;
}

.w-15 {
    width: 15% !important;
}

.w-20 {
    width: 20% !important;
}

.w-25 {
    width: 25% !important;
}

.w-30 {
    width: 30% !important;
}

.w-40 {
    width: 40% !important;
}

.w-50 {
    width: 50% !important;
}

.pt-2 {
    padding-top: 20px !important;
}

.form-details-label {
    background: rgba(70, 104, 229, 0.1);
    padding: 8px;
    color: rgba(62, 62, 62, 1);
    font-size: 13px;
    font-weight: 500;
}

.form-details-value {
    padding-left: 8px;
    color: rgba(62, 62, 62, 1);
    font-weight: 400;
    opacity: 0.8;
}

#main-menu {
    a {
        color: #24272d;

        svg {
            color: #24272d;
        }
    }

    .active {
        color: #ffffff;
        background-color: #005e99;

        svg {
            color: #ffffff;
        }
    }
}

.MuiFormHelperText-root {
    margin-top: -10px !important;
}

.Mui-error.MuiFormHelperText-root  {
    width: fit-content !important;
    padding: 0 5px !important;
    background-color: white !important;
    z-index: 9 !important;
}

// sidebar toggle color
.sidebar-nav .nav-group-toggle::after {
    background-image: var(--cui-sidebar-nav-group-indicator, url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27rgba%280, 0, 0, 0.6%29%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e"));
}

.sidebar-nav .nav-link:hover.nav-group-toggle::after {
    background-image: var(--cui-sidebar-nav-group-indicator-hover, url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27rgba%280, 0, 0, 0.77%29%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e"))
}

.icon-button-grey {
    border-color: transparent !important;
    background-color: rgba(52, 58, 64, 0.1) !important;
    color: #343a40 !important;
    width: 32px;
    height: 32px;
    font-size: 16px;
}

.icon-button-green {
    border-color: transparent !important;
    background-color: rgba(0, 128, 0, 0.1) !important;
    color: #343a40 !important;
    width: 32px;
    height: 32px;
    font-size: 16px;
}

.icon-button-red {
    border-color: transparent !important;
    background-color: rgba(255, 0, 0, 0.1) !important;
    color: red !important;
    width: 32px;
    height: 32px;
    font-size: 16px;
}

// icon button
.icon-btn:hover {
    color: white !important;
    background-color: #343a40 !important;
}

.icon-btn:focus {
    box-shadow: 0 0 0 0.15rem rgb(52 58 64 / 50%);
}

// status
.status-active {
    padding-left: 2%;
    padding-right: 2%;
    font-size: 11px !important;
    border-radius: 50rem !important;
    color: #2ab57d;
    background-color: rgba(42, 181, 125, 0.18);
    width: fit-content;
    text-transform: capitalize;
}

.status-inactive {
    padding-left: 2%;
    padding-right: 2%;
    font-size: 11px !important;
    border-radius: 50rem !important;
    color: #ff6482;
    background-color: rgba(255, 100, 130, 0.18);
    width: fit-content;
    text-transform: capitalize;
}

.status-expired {
    padding-left: 2%;
    padding-right: 2%;
    font-size: 11px !important;
    border-radius: 50rem !important;
    color: #ff6482;
    background-color: rgba(255, 100, 130, 0.18);
    width: fit-content;
    text-transform: capitalize;
}

.status-pending {
    padding-left: 2%;
    padding-right: 2%;
    font-size: 11px !important;
    border-radius: 50rem !important;
    color: #343a40;
    background-color: rgba(52, 58, 64, 0.18);
    width: fit-content;
    text-transform: capitalize;
}